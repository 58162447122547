import type { McpNotificationFragment } from '@wise/graphql'

export const getNotificationCategories = (
  notifications: McpNotificationFragment[],
): McpNotificationFragment['category'][] => {
  const categories = new Set<McpNotificationFragment['category']>()
  notifications.forEach((notification) => {
    categories.add(notification.category)
  })
  return Array.from(categories)
}

export const formatNotificationCategory = (
  category: McpNotificationFragment['category'],
): string => {
  return category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()
}
