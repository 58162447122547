import {
  McpNotificationFragment,
  useMarkNotificationAsReadMutation,
} from '@wise/graphql'
import { invariant } from '@wise/utils'
import cx from 'classnames'
import dayjs from 'dayjs'
import Router from 'next/router'
import * as React from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import { route } from '~generated/routes'
import WLogo from '~shared/components/Icons/svg/w-logo.svg'
import { triggerErrorModal } from '~shared/components/Modal/preset/GenericModal/GenericModal'
import { useMode } from '~shared/hooks/useMode'
import { useUser } from '~shared/services/firebase/auth/hooks'

interface Props {
  notification: Extract<
    McpNotificationFragment,
    { __typename: 'AmazonFlexNewRateCardRoutesNotification' }
  >
  older: boolean
  markAsRead: (id: string) => void
}

const AmazonFlexNewRateCardRoutes = ({
  notification,
  older,
  markAsRead,
}: Props): JSX.Element => {
  const mode = useMode()
  const { t } = useTranslation()
  const user = useUser()
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation()

  const routeUrl = React.useMemo(() => {
    switch (mode) {
      case 'WAP': {
        const mcId = notification.mainContractor?.id
        if (!mcId) return null
        return route('/main-contractors/[mcId]/rate-cards', mcId)
      }
      case 'MCP': {
        return route('/settings/rate-cards')
      }
      default:
        return null
    }
  }, [mode, notification.mainContractor?.id])

  const handleClick = useCallback(async () => {
    markAsRead(notification.id)

    if (!notification.read) {
      try {
        invariant(user, 'User must be logged in to mark notification as read')
        const result = await markNotificationAsRead({
          variables: {
            input: {
              notificationId: notification.id,
              userId: user.id,
            },
          },
        })
        if (!result.data?.markNotificationAsRead) return
        markAsRead(notification.id)
      } catch (error) {
        triggerErrorModal({ error })
      }
    }

    if (routeUrl === null) return
    Router.push(routeUrl)
  }, [
    markAsRead,
    markNotificationAsRead,
    notification.id,
    notification.read,
    routeUrl,
    user,
  ])

  const count = React.useMemo(
    () => Number(notification.count),
    [notification.count],
  )

  return (
    <div
      className={cx(
        'flex cursor-pointer items-center rounded-lg border-2 border-grey p-3',
        notification.read ? 'bg-white' : 'bg-red/5',
      )}
      onClick={handleClick}
    >
      <div className='flex flex-row items-center gap-3'>
        <div className='flex h-8 w-8 items-center justify-center rounded-full bg-purple'>
          <WLogo className='text-lg text-white' />
        </div>
        <div className='flex flex-col items-start gap-1'>
          <div className='flex flex-row items-center gap-2'>
            <p className='font-bold'>{t('notifications.system')}</p>
            <p
              className={twMerge(
                'rounded-full py-[0.07rem] px-2 text-sm',
                notification.read ? 'bg-[#f5f5f5]' : 'bg-[#f2b6b6]',
              )}
            >
              {t('worklog.notifications.new-rate-card-routes-tag')}
            </p>
            <p className='text-grey-darker'>
              {older
                ? dayjs(notification.createdAt).format('ddd, DD MMM YY')
                : dayjs(notification.createdAt).format('HH:mm')}
            </p>
          </div>
          <p>{t('worklog.notifications.new-rate-card-routes', { count })}</p>
        </div>
      </div>
    </div>
  )
}

export default AmazonFlexNewRateCardRoutes
