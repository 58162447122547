import type { McpNotificationFragment } from '@wise/graphql'
import cx from 'classnames'
import { useState } from 'react'

import ArrowIcon from '~shared/components/Icons/svg/arrow.svg'
import useTheme from '~shared/hooks/useTheme'
import { SocketStatus } from '~shared/services/ws'

import NotificationCategories from './NotificationCategories'
import NotificationsSearch from './NotificationsSearch'
import NotificationsSocketStatus from './NotificationsSocketStatus'

interface Props {
  status: SocketStatus
  search: string
  setSearch: (search: string) => void
  categories: McpNotificationFragment['category'][]
  category: McpNotificationFragment['category'] | null
  setCategory: (category: McpNotificationFragment['category']) => void
}

const NotificationsHeader = ({
  status,
  search,
  setSearch,
  categories,
  category,
  setCategory,
}: Props): JSX.Element => {
  const theme = useTheme()

  const [viewHistory, setViewHistory] = useState<boolean>(false)

  return (
    <div className='p-5 pb-0'>
      <div className='mb-1 flex min-w-400 flex-row items-center justify-evenly'>
        {viewHistory ? (
          <a
            className={cx(
              'flex w-full cursor-pointer items-center text-left text-sm font-bold',
              theme === 'purple' ? 'text-purple' : 'text-green',
            )}
            onClick={() => setViewHistory(false)}
          >
            <ArrowIcon className='mr-1 rotate-90 text-xs' />
            Back
          </a>
        ) : (
          <div className='w-full'></div>
        )}
        <h3
          data-testid='notification-header'
          className={cx(
            'w-full text-center text-xl font-extrabold',
            theme === 'purple' ? 'text-purple' : 'text-green',
          )}
        >
          Notifications
        </h3>
        {!viewHistory ? (
          <a
            data-testid='notification-view-history'
            className='w-full cursor-pointer text-right underline'
            onClick={() => setViewHistory(true)}
          >
            View history
          </a>
        ) : (
          <div className='w-full'></div>
        )}
      </div>
      <NotificationsSocketStatus status={status} />
      <NotificationCategories
        theme={theme}
        categories={categories}
        category={category}
        setCategory={setCategory}
      />
      {viewHistory ? (
        <NotificationsSearch search={search} setSearch={setSearch} />
      ) : null}
    </div>
  )
}

export default NotificationsHeader
