import SearchIcon from '~shared/components/Icons/svg/search.svg'

interface Props {
  search: string
  setSearch: (search: string) => void
}

const NotificationsSearch = ({ search, setSearch }: Props): JSX.Element => {
  return (
    <div className='relative mb-3 flex flex-row items-center justify-evenly'>
      <SearchIcon className='absolute top-0 left-3 bottom-0 my-auto text-grey-darker' />
      <input
        data-testid='notification-searchbar'
        type='text'
        className='w-full rounded-lg border-2 border-grey-dark p-2 pl-8 font-light focus-within:outline-0'
        placeholder='Search history'
        autoFocus={true}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  )
}

export default NotificationsSearch
