import {
  FloatingFocusManager,
  autoPlacement,
  autoUpdate,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { motion } from 'framer-motion'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import create from 'zustand'

import ChevronIcon from '~shared/components/Icons/svg/arrow-down.svg'
import { NavOption, useSidenavOptions } from '~shared/permissions/sidenav'
import { isOnActivePath } from '~shared/utils/activePath'
import { getTabHref } from '~shared/utils/routing'

import DropdownContents from './FluidNav/DropdownContents'

type NavStore = {
  stack: number[]
  setStack: (stack: number[]) => void
}

const useNavStore = create<NavStore>((set) => ({
  stack: [],
  setStack: (stack) => set({ stack }),
}))

type FluidNavItemProps = {
  open: boolean
  onOpen: (setOpen: boolean) => void
  onNavigate: () => void
  active: boolean
  option: NavOption
  testId: string
}

const FluidNavItem = ({
  open,
  onOpen,
  onNavigate,
  testId,
  option,
  active: underline,
}: FluidNavItemProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: onOpen,
    middleware: [
      autoPlacement({
        allowedPlacements: ['bottom-start', 'bottom', 'bottom-end'],
        alignment: 'start',
      }),
      shift({ padding: { left: 16, right: 16 } }),
    ],
    whileElementsMounted: autoUpdate,
  })

  const hover = useHover(context, {
    handleClose:
      option.type === 'dropdown'
        ? safePolygon({ blockPointerEvents: true })
        : undefined,
  })
  const dismiss = useDismiss(context)
  const role = useRole(context)
  const focus = useFocus(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    focus,
    hover,
    dismiss,
    role,
  ])

  if (option.type === 'direct-link') {
    const url =
      getTabHref(
        { filters: option.queryParams ?? [], href: option.url },
        router.query,
      ) ?? option.url

    return (
      <Link href={url}>
        <a
          {...getReferenceProps()}
          ref={refs.setReference}
          onClick={onNavigate}
          className={twMerge(
            'relative z-[1] flex cursor-pointer flex-row items-center p-3 text-center',
            underline
              ? 'font-black'
              : open
              ? 'font-light text-theme-dark'
              : 'font-light text-black/40',
          )}
        >
          <span>{t(option.title)}</span>
          {underline ? (
            <motion.div
              layoutId='underline-pip'
              className='absolute left-0 right-0 bottom-0 bg-gradient-to-r from-theme to-theme-light'
              style={{ height: 2 }}
            />
          ) : null}
        </a>
      </Link>
    )
  }

  return (
    <>
      <button
        {...getReferenceProps()}
        ref={refs.setReference}
        data-testid={testId}
        className={twMerge(
          'relative z-[1] flex cursor-pointer flex-row items-center p-3 text-center',
          underline
            ? 'font-black'
            : open
            ? 'font-light text-theme-dark'
            : 'font-light text-black/40',
        )}
      >
        <span>{t(option.title)}</span>
        <ChevronIcon className='ml-1.5 w-2.5 opacity-50 transition-transform' />
        {underline ? (
          <motion.div
            layoutId='underline-pip'
            className='absolute left-0 right-0 bottom-0 bg-gradient-to-r from-theme to-theme-light'
            style={{ height: 2 }}
          />
        ) : null}
      </button>
      {open ? (
        <FloatingFocusManager
          context={context}
          modal={false}
          initialFocus={-1}
          returnFocus={false}
        >
          <div
            {...getFloatingProps()}
            ref={refs.setFloating}
            style={floatingStyles}
            className='z-10'
          >
            <motion.div
              className='origin-top-left rounded-b-md bg-white shadow-glow'
              initial={{ opacity: 0, scaleY: 0.9 }}
              animate={{ opacity: 1, scaleY: 1 }}
              transition={{ ease: 'easeInOut' }}
            >
              <DropdownContents
                config={option.config}
                onNavigate={onNavigate}
              />
            </motion.div>
          </div>
        </FloatingFocusManager>
      ) : null}
    </>
  )
}

const FluidNav = () => {
  const router = useRouter()
  const { stack, setStack } = useNavStore()

  const options = useSidenavOptions()

  const activeIndex = options.findIndex((o) => {
    const path = o.activePath ?? (o.type === 'direct-link' ? o.url : null)
    return path ? isOnActivePath(path, router.pathname) : false
  })

  const current = stack.length > 0 ? stack[stack.length - 1] : null

  return (
    <div className='h-full'>
      <div className='flex h-full flex-row flex-wrap items-stretch xl:flex-nowrap'>
        {options.map((option, ix) => {
          return (
            <FluidNavItem
              key={option.id}
              option={option}
              open={current === ix}
              active={activeIndex === ix}
              onOpen={(b) => {
                if (b) {
                  if (current === ix) return
                  return setStack([...stack, ix])
                } else {
                  return setStack(stack.filter((s) => s !== ix))
                }
              }}
              testId={`nav-item-${option.id}`}
              onNavigate={() => setStack([])}
            />
          )
        })}
      </div>
    </div>
  )
}

export default FluidNav
