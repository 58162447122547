import { Tab } from '~shared/components/Tabs/Tabs'
import { stringifyQueryParams } from '~shared/hooks/useParams/qs'

export const getTabHref = (
  tab: Pick<Tab, 'href' | 'filters'>,
  query: Record<string, unknown>,
): string | undefined => {
  const filters = tab.filters ?? []
  if (filters.length === 0) return tab.href

  const params = filters.reduce<Record<string, unknown>>((acc, filter) => {
    const value = query[filter]
    return value === undefined ? acc : { ...acc, [filter]: value }
  }, {})

  const queryString = stringifyQueryParams(params)
  return queryString ? `${tab.href}?${queryString}` : tab.href
}
