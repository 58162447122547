import cx from 'classnames'

import { SocketStatus } from '~shared/services/ws'

const NotificationsSocketStatus = ({
  status,
}: {
  status: SocketStatus
}): JSX.Element => {
  return (
    <div className='mb-3 flex items-center justify-center text-center'>
      <p className='flex items-center gap-1 text-grey-darker'>
        <span
          className={cx('inline-block h-2 w-2 rounded-full', {
            'bg-green': status === 'connected',
            'bg-orange': status === 'connecting',
            'bg-red': status === 'disconnected',
            'bg-grey-800': status === 'inactive',
          })}
        ></span>
        {status === 'disconnected'
          ? 'Disconnected'
          : status === 'connected'
          ? 'Connected'
          : 'Connecting...'}
      </p>
    </div>
  )
}

export default NotificationsSocketStatus
