import type { McpNotificationFragment } from '@wise/graphql'
import { now } from '@wise/utils'
import dayjs, { ConfigType } from 'dayjs'

import { Timeline, TimelineNotifications } from '../types'

export const isExpired = (date: ConfigType) => {
  const today = now().format('YYYY-MM-DD')
  const expiryDate = dayjs(date).format('YYYY-MM-DD')

  return expiryDate < today
}

const isTodayDate = (date: string) => {
  const today = new Date()
  const notificationDate = new Date(date)
  return (
    today.getDate() === notificationDate.getDate() &&
    today.getMonth() === notificationDate.getMonth() &&
    today.getFullYear() === notificationDate.getFullYear()
  )
}

const isYesterdayDate = (date: string) => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  const notificationDate = new Date(date)
  return (
    yesterday.getDate() === notificationDate.getDate() &&
    yesterday.getMonth() === notificationDate.getMonth() &&
    yesterday.getFullYear() === notificationDate.getFullYear()
  )
}

const isOlderDate = (date: string) => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  const notificationDate = new Date(date)
  return notificationDate < yesterday
}

export const getTimelineNotifications = (
  notifications: McpNotificationFragment[],
  category: McpNotificationFragment['category'] | null,
  search: string,
): TimelineNotifications[] => {
  const timeline: Timeline[] = [
    Timeline.Today,
    Timeline.Yesterday,
    Timeline.Older,
  ]

  //   get notifications for each timeline
  const timelineNotifications = timeline.map((key) => {
    const notificationsForTimeline = notifications
      .filter((n) => {
        const isToday = key === Timeline.Today && isTodayDate(n.createdAt)
        const isYesterday =
          key === Timeline.Yesterday && isYesterdayDate(n.createdAt)
        const isOlder = key === Timeline.Older && isOlderDate(n.createdAt)

        const isCategoryMatch = category ? n.category === category : true
        const isSearchMatch = search
          ? n.category.toLowerCase().includes(search) ||
            ('sc' in n && n.sc?.firstName.toLowerCase().includes(search)) ||
            ('sc' in n && n.sc?.lastName?.toLowerCase().includes(search))
          : true

        return (
          (isToday || isYesterday || isOlder) &&
          isCategoryMatch &&
          isSearchMatch
        )
      })
      .sort((a, b) => b.createdAt.localeCompare(a.createdAt))

    return {
      key,
      notifications: notificationsForTimeline,
      unreadCount: notificationsForTimeline.filter((n) => !n.read).length,
    }
  })

  //   remove timelines with no notifications
  const filteredTimelineNotifications = timelineNotifications.filter(
    (t) => t.notifications.length > 0,
  )

  return filteredTimelineNotifications
}
