import { AnimatePresence, motion } from 'framer-motion'
import { createTeleporter } from 'react-teleporter'

export const LeftOverlayPortal = createTeleporter()

interface Props {
  visible?: boolean
  className?: string
}

const LeftOverlay: React.FC<Props> = ({
  children,
  className,
  visible = true,
}) => {
  return (
    <LeftOverlayPortal.Source>
      <AnimatePresence>
        {visible ? (
          <motion.div
            initial={{ opacity: 0, transform: 'translateX(-50%)' }}
            animate={{ opacity: 1, transform: 'translateX(0%)' }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, transform: 'translateX(-50%)' }}
            className={className}
          >
            {children}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </LeftOverlayPortal.Source>
  )
}

export default LeftOverlay
