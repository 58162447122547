import { isNonEmptyString } from '@wise/utils'
import * as React from 'react'

import { APP_ENV } from '~shared/config/app'
import {
  APP_BUILD_SHA,
  APP_BUILD_TAG,
  APP_VERSION,
} from '~shared/config/version'

import WithWiseLogo from '../WithWiseLogo/WithWiseLogo'

const WithWiseFooter = (): JSX.Element => {
  const versionFragments = [
    APP_ENV,
    APP_VERSION,
    APP_BUILD_TAG,
    APP_BUILD_SHA?.slice(0, 7),
  ].filter(isNonEmptyString)

  return (
    <footer className='mt-auto flex flex-row'>
      <div className='ml-auto flex flex-col items-end'>
        <div className='w-32 pt-6 pb-1'>
          <WithWiseLogo color='darkGrey' />
        </div>
        <p className='flex flex-row gap-2 text-xs font-bold text-black/40 opacity-20 transition-opacity hover:opacity-100'>
          {versionFragments.map((fragment) => (
            <span key={fragment} className='bg-black/5 px-1 py-0.5'>
              {fragment}
            </span>
          ))}
        </p>
      </div>
    </footer>
  )
}

export default React.memo(WithWiseFooter)
