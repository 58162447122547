import { isDefined } from '@wise/utils'
import cx from 'classnames'
import { motion } from 'framer-motion'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import useTheme from '~shared/hooks/useTheme'

type ChildWithOptions = {
  child: React.ReactChild
  disabled?: boolean
  isButton?: boolean
}

const isChildWithOptions = (item: unknown): item is ChildWithOptions => {
  return typeof item === 'object' && isDefined((item as ChildWithOptions).child)
}

interface ItemProps {
  item: React.ReactChild | ChildWithOptions
}

interface Props {
  items: ItemProps['item'][]
  className?: string
}

const ContextMenuItem = ({ item }: ItemProps) => {
  const theme = useTheme()
  const [focus, setFocus] = React.useState(false)
  const [hover, setHover] = React.useState(false)

  const isDisabled = React.useMemo(() => {
    return isChildWithOptions(item)
      ? (item.disabled || item.isButton === false) ?? false
      : false
  }, [item])

  const isButton = React.useMemo(() => {
    return isChildWithOptions(item) ? item.isButton ?? true : true
  }, [item])

  const showPip = React.useMemo(() => {
    return !isDisabled && (hover || focus)
  }, [focus, hover, isDisabled])

  return (
    <li
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      className={cx(
        'relative',
        isButton && 'cursor-pointer',
        !isButton
          ? ''
          : isDisabled
          ? 'bg-grey-light hover:bg-black/5'
          : theme === 'purple'
          ? 'hover:bg-purple/5'
          : 'hover:bg-green/5',
      )}
    >
      {showPip ? (
        <motion.div
          layoutId='context-menu-layout'
          className={cx(
            'pointer-events-none absolute left-2 top-2 bottom-2 w-1 select-none rounded-sm transition-colors',
            theme === 'purple' ? 'bg-purple' : 'bg-green',
          )}
        />
      ) : null}
      {isChildWithOptions(item) ? item.child : item}
    </li>
  )
}

const ContextMenu = ({ items, className }: Props): JSX.Element => {
  return (
    <ul
      className={twMerge(
        'flex max-w-300 flex-col divide-y divide-grey-dark rounded-lg border-grey-dark text-base',
        className,
      )}
    >
      {items.map((item, ix) => (
        <ContextMenuItem key={ix} item={item} />
      ))}
    </ul>
  )
}

export default ContextMenu
