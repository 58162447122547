import type { McpNotificationFragment } from '@wise/graphql'
import cx from 'classnames'
import { kebabCase } from 'lodash/fp'

import type { Theme } from '~shared/hooks/useTheme'

import { formatNotificationCategory } from '../utils/categories'

interface Props {
  theme: Theme
  categories: McpNotificationFragment['category'][]
  category: McpNotificationFragment['category'] | null
  setCategory: (category: McpNotificationFragment['category']) => void
}

const NotificationCategories = ({
  theme,
  categories,
  category,
  setCategory,
}: Props): JSX.Element => {
  if (categories.length === 0) return <div className='mb-3'></div>

  return (
    <div className='mb-3 flex flex-row border-b-2 border-b-grey-dark'>
      {categories.map((c) => (
        <p
          data-testid={`notification-catagory-${kebabCase(c)}`}
          key={c}
          className={cx(
            'text-md relative min-w-50 max-w-150 cursor-pointer py-2 px-4 font-bold',
            category === c
              ? `b-0 l-0 after:absolute after:-bottom-[0.2rem] after:left-0 after:h-1 after:w-full after:content-[''] ${
                  theme === 'purple'
                    ? 'text-purple after:bg-purple'
                    : 'text-green after:bg-green'
                }`
              : '',
          )}
          onClick={() => setCategory(c)}
        >
          {formatNotificationCategory(c)}
        </p>
      ))}
    </div>
  )
}

export default NotificationCategories
