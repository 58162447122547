import { getInitials, isDefined } from '@wise/utils'
import cx from 'classnames'
import Link from 'next/link'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import ChangeLanguageModal from '~shared/components/ChangeLanguageModal/ChangeLanguageModal'
import ContextMenu from '~shared/components/ContextMenu/ContextMenu'
import I18nIcon from '~shared/components/Icons/svg/i18n.svg'
import PowerOffIcon from '~shared/components/Icons/svg/power-off.svg'
import { pushModal } from '~shared/components/Modal/useModal'
import PadlockIcon from '~shared/components/Svgs/Keylock.svg'
import { TRANSLATIONS_ENABLED } from '~shared/i18n/i18n'
import { useUser } from '~shared/services/firebase/auth/hooks'

import { getDriverName } from '@/subcontractors/tables/helpers'

const ProfileMenu = (): JSX.Element => {
  const { t } = useTranslation()
  const user = useUser()

  return (
    <ContextMenu
      className='relative max-w-[450px]'
      items={[
        {
          child: (
            <div
              data-testid='profile-menu-account-details'
              key='logged-in-as'
              className='flex flex-row items-center gap-3 overflow-hidden text-ellipsis whitespace-pre-line p-3'
            >
              <div
                className={cx(
                  'flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-gradient-to-tr from-theme-inverted to-theme-inverted-light text-sm font-bold text-white',
                )}
              >
                {getInitials(user?.firstName, user?.lastName ?? undefined)}
              </div>
              <div className='overflow-hidden'>
                <h3 className='text-lg'>
                  {user ? getDriverName(user) : 'N/A'}
                </h3>
                <p className='break-words text-xs font-light text-grey-800'>
                  {user?.email ?? 'N/A'}
                </p>
              </div>
            </div>
          ),
          isButton: false,
        },
        TRANSLATIONS_ENABLED ? (
          <button
            key='change-language'
            data-testid='change-language-button'
            className='flex w-full flex-row items-center gap-2 py-2 px-6'
            onClick={() =>
              pushModal({
                id: 'change-language-modal',
                component: ChangeLanguageModal,
              })
            }
          >
            <I18nIcon className='text-xl text-theme' />{' '}
            {t('side_nav.actions.change_language')}
          </button>
        ) : null,
        <Link key='change-password' href='/auth/change-password'>
          <a
            data-testid='change-password-button'
            className='flex w-full flex-row items-center gap-2 py-2 px-6'
            tabIndex={0}
          >
            <PadlockIcon className='text-xl text-theme' />{' '}
            {t('side_nav.actions.change_password')}
          </a>
        </Link>,
        <Link key='logout' href='/logout'>
          <a
            data-testid='logout-button'
            className='flex w-full flex-row items-center gap-2 py-2 px-6'
            tabIndex={0}
          >
            <PowerOffIcon className='text-xl text-theme' />{' '}
            {t('side_nav.actions.logout')}
          </a>
        </Link>,
      ].filter(isDefined)}
    />
  )
}

export default React.memo(ProfileMenu)
