import { useMarkNotificationAsReadMutation } from '@wise/graphql'
import { invariant } from '@wise/utils'
import router from 'next/router'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import { route } from '~generated/routes'
import AlertIcon from '~shared/components/Icons/svg/exclamation-triangle.svg'
import { triggerErrorModal } from '~shared/components/Modal/preset/GenericModal/GenericModal'
import { LicenceCheckeNotification } from '~shared/graphql/types'
import { useUser } from '~shared/services/firebase/auth/hooks'

import BaseNotification from './BaseNotification'

interface Props {
  notification: LicenceCheckeNotification
  older: boolean
  markAsRead: (id: string) => void
}

const LicenceCheck = ({
  notification,
  older,
  markAsRead,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const user = useUser()
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation()

  const handleClick = useCallback(async () => {
    markAsRead(notification.id)

    if (!notification.read) {
      try {
        invariant(user, 'User is not authenticated')
        const result = await markNotificationAsRead({
          variables: {
            input: {
              notificationId: notification.id,
              userId: user.id,
            },
          },
        })
        if (!result.data?.markNotificationAsRead) return
        markAsRead(notification.id)
      } catch (error) {
        triggerErrorModal({ error })
      }
    }
    return router.push(
      route('/drivers/[id]/engagement-details', notification.sc.id),
    )
  }, [
    markAsRead,
    markNotificationAsRead,
    notification.id,
    notification.sc.id,
    notification.read,
    user,
  ])

  return (
    <div
      className={twMerge(
        'flex min-w-max cursor-pointer items-center rounded-lg border-2 border-grey p-3',
        !notification.attentionNeeded ? 'bg-white' : 'bg-yellow-light/40',
        notification.success ? 'bg-white' : 'bg-red-light/40',
      )}
      onClick={handleClick}
    >
      <BaseNotification
        notification={notification}
        older={older}
        category='Driver check'
        customStyle='bg-green-100 font-bold'
      >
        <div className='flex items-center'>
          {!notification.success ? (
            <p className='flex items-center text-xs font-bold text-red'>
              <AlertIcon className='mr-1 text-sm ' />
              {!notification.validationErrors ||
              notification.validationErrors.length === 0
                ? t('notifications.licence-check.valid-error')
                : t('notifications.licence-check.invalid-error')}
            </p>
          ) : notification.attentionNeeded ? (
            <p className='flex items-center text-xs'>
              <AlertIcon className='mr-1 text-sm text-orange' />{' '}
              <b className='mr-1 font-bold text-orange'>
                {t('notifications.licence-check.attention')}
              </b>
            </p>
          ) : (
            <p>{t('notifications.licence-check.check-completed')}</p>
          )}
        </div>
      </BaseNotification>
    </div>
  )
}

export default LicenceCheck
