import type { User } from '@wise/graphql'
import { getInitials, isDefined } from '@wise/utils'
import cx from 'classnames'
import { useMemo } from 'react'

import useTheme from '~shared/hooks/useTheme'

import { getDriverName } from '@/subcontractors/tables/helpers'

interface Props {
  driver: Pick<User, 'firstName' | 'profilePicture' | 'lastName'>
  name?: boolean
  className?: string
}

const DriverProfile = ({
  driver,
  name = true,
  className,
}: Props): JSX.Element => {
  const theme = useTheme()

  const imageUrl = useMemo(() => {
    return driver.profilePicture?.assets
      ?.map((asset) => asset?.publicURL)
      .find(isDefined)
  }, [driver.profilePicture?.assets])

  return (
    <div
      className={cx(
        `flex align-middle text-lg font-bold text-black `,
        className,
      )}
    >
      <div
        className={cx(
          'mr-2 flex h-8 w-8 items-center justify-center rounded-full text-sm font-bold',
          theme === 'purple'
            ? 'bg-purple/20 text-purple'
            : 'bg-green/20 text-green',
        )}
      >
        {imageUrl ? (
          <div className='relative h-8 w-8 overflow-hidden rounded-full'>
            <img
              className='object-cover'
              src={imageUrl}
              alt={getDriverName(driver)}
            />
          </div>
        ) : (
          <p>{getInitials(driver.firstName, driver.lastName ?? undefined)}</p>
        )}
      </div>
      {name && (
        <p className='flex h-full items-center'>
          {driver.firstName} {driver.lastName}
        </p>
      )}
    </div>
  )
}

export default DriverProfile
