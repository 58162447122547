import type { McpNotificationFragment } from '@wise/graphql'

export enum Timeline {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Older = 'Older',
}

export type TimelineNotifications = {
  key: Timeline
  notifications: McpNotificationFragment[]
  unreadCount: number
}
