import { isServerSide } from '~shared/services/context'

export const isOnActivePath = (
  activePath: string | RegExp,
  url?: string,
): boolean => {
  if (!url && isServerSide()) return false
  url = url ?? window.location.pathname
  if (typeof activePath === 'string') {
    return url.startsWith(activePath)
  }
  return activePath.test(url)
}
