import { McpNotificationFragment } from '@wise/graphql'
import { chunk } from 'lodash'
import * as React from 'react'
import { useInView } from 'react-intersection-observer'

import AmazonFlexNewDrivers from '~shared/components/Notifications/preset/AmazonFlexNewDrivers'
import AmazonFlexNewRateCardRoutes from '~shared/components/Notifications/preset/AmazonFlexNewRateCardRoutes'
import DefaultNotification from '~shared/components/Notifications/preset/DefaultNotification'
import EngagementExpiringEvidence from '~shared/components/Notifications/preset/EngagementExpiringEvidence'
import LicenceCheck from '~shared/components/Notifications/preset/LicenceCheck'
import ReuploadedEvidence from '~shared/components/Notifications/preset/ReuploadedEvidence'
import SchedulingDriverOffboarded from '~shared/components/Notifications/preset/SchedulingDriverOffboarded'
import SchedulingRejectedRoute from '~shared/components/Notifications/preset/SchedulingRejectedRoute'

type FunnelProps = {
  notification: McpNotificationFragment
  older: boolean
  markAsRead: (id: string) => void
}

const Notification = ({ notification, older, markAsRead }: FunnelProps) => {
  switch (notification.__typename) {
    case 'SchedulingRejectedRouteNotification':
      return (
        <SchedulingRejectedRoute
          notification={notification}
          older={older}
          markAsRead={markAsRead}
        />
      )
    case 'SchedulingDriverOffboardedNotification':
      return (
        <SchedulingDriverOffboarded
          notification={notification}
          older={older}
          markAsRead={markAsRead}
        />
      )
    case 'EngagementExpiringEvidenceNotification':
    case 'ExpiringVehicleInsuranceNotification':
      return (
        <EngagementExpiringEvidence
          notification={notification}
          older={older}
          markAsRead={markAsRead}
        />
      )
    case 'ReuploadedEngagementEvidenceNotification':
      return (
        <ReuploadedEvidence
          notification={notification}
          older={older}
          markAsRead={markAsRead}
        />
      )
    case 'AmazonFlexNewDriversNotification':
      return (
        <AmazonFlexNewDrivers
          notification={notification}
          older={older}
          markAsRead={markAsRead}
        />
      )
    case 'AmazonFlexNewRateCardRoutesNotification':
      return (
        <AmazonFlexNewRateCardRoutes
          notification={notification}
          older={older}
          markAsRead={markAsRead}
        />
      )
    case 'LicenceCheckCompletedNotification':
      return (
        <LicenceCheck
          notification={notification}
          older={older}
          markAsRead={markAsRead}
        />
      )
    default:
      return (
        <DefaultNotification
          notification={notification}
          older={older}
          markAsRead={markAsRead}
        />
      )
  }
}

type Props = {
  notifications: McpNotificationFragment[]
  older: boolean
  markAsRead: (id: string) => void
}

const CHUNK_SIZE = 25 // Number of notifications per chunk (25 is around a full height of a standard screen)
const NOTIFICATION_HEIGHT = 94 // Height of a single notification
const GAP = 6 // Gap between notifications

const NotificationChunk = ({
  notifications,
  older,
  markAsRead,
}: Props): JSX.Element => {
  const { ref, inView } = useInView({ threshold: 0 })

  const height = React.useMemo(() => {
    const gaps = (notifications.length - 1) * GAP
    return NOTIFICATION_HEIGHT * notifications.length + gaps
  }, [notifications.length])

  return (
    <div
      ref={ref}
      className='flex flex-col'
      style={{ gap: GAP, minHeight: height, height, maxHeight: height }}
    >
      {inView
        ? notifications.map((n, ix) => (
            <div
              key={`${n.id}-${ix}`}
              className='m-0 flex flex-col'
              style={{
                minHeight: NOTIFICATION_HEIGHT,
                maxHeight: NOTIFICATION_HEIGHT,
                height: NOTIFICATION_HEIGHT,
              }}
            >
              <Notification
                markAsRead={markAsRead}
                notification={n}
                older={older}
              />
            </div>
          ))
        : null}
    </div>
  )
}

const NotificationsList = ({
  notifications,
  older,
  markAsRead,
}: Props): JSX.Element => {
  const chunked = React.useMemo(
    () => chunk(notifications, CHUNK_SIZE),
    [notifications],
  )

  return (
    <div className='flex flex-col' style={{ gap: GAP }}>
      {chunked.map((chunk, ix) => (
        <NotificationChunk
          key={ix}
          notifications={chunk}
          older={older}
          markAsRead={markAsRead}
        />
      ))}
    </div>
  )
}

export default NotificationsList
