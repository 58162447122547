import type { McpNotificationFragment } from '@wise/graphql'
import cx from 'classnames'
import { Fragment, useMemo } from 'react'

import NotificationsList from '~shared/components/Notifications/components/NotificationsList'

import type { TimelineNotifications } from '../types'
import { getTimelineNotifications } from '../utils/notifications'

interface Props {
  notifications: McpNotificationFragment[]
  search: string
  category: McpNotificationFragment['category'] | null
  areCategoriesVisible: boolean
  markAsRead: (id: string) => void
}

const NotificationsWrapper = ({
  notifications,
  category,
  search,
  areCategoriesVisible,
  markAsRead,
}: Props): JSX.Element => {
  const timelineNotifications: TimelineNotifications[] = useMemo(() => {
    return getTimelineNotifications(notifications, category, search)
  }, [notifications, category, search])

  return (
    <div className='flex max-h-[60vh] w-full flex-col items-stretch overflow-y-auto overflow-x-hidden p-5 pt-0'>
      {timelineNotifications.length > 0 ? (
        timelineNotifications.map((n) => (
          <Fragment key={n.key}>
            <div className='flex w-full flex-col'>
              <p className='text-md mb-3  flex flex-row items-center text-grey-darker'>
                {n.key}
                {n.unreadCount > 0 && (
                  <span className='ml-1 flex aspect-square h-4 w-4 items-center justify-center rounded-full bg-red/10 text-xs font-bold text-red'>
                    {n.unreadCount}
                  </span>
                )}
              </p>
            </div>
            <NotificationsList
              notifications={n.notifications}
              older={n.key === 'Older'}
              markAsRead={markAsRead}
            />
          </Fragment>
        ))
      ) : (
        <>
          <p
            data-testid='notification-search-result'
            className={cx(
              'text-md mb-3 flex w-full flex-row items-center text-grey-darker',
              !areCategoriesVisible && 'justify-center',
            )}
          >
            No notifications found
          </p>
        </>
      )}
    </div>
  )
}

export default NotificationsWrapper
