import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import { locales } from '~shared/i18n/i18n'

import ModalHeader from '../UI/ModalHeader'

const ChangeLanguageModal = () => {
  const { t, i18n } = useTranslation()
  return (
    <div className='w-[600px] rounded-xl bg-white p-6 shadow-md'>
      <ModalHeader className='pr-12'>
        {t('change_language.modal_title')}
      </ModalHeader>
      <div className='grid grid-cols-2 justify-center gap-3'>
        {locales.map((l) => {
          const active = i18n.language === l.code
          return (
            <button
              key={l.code}
              data-testid={`change-language-${l.code}`}
              className={twMerge(
                'flex flex-col items-center gap-1 rounded-md bg-grey-200 p-6 text-black',
                active && 'bg-theme text-white',
              )}
              onClick={() => i18n.changeLanguage(l.code)}
            >
              <p className='text-4xl'>{l.flag}</p>
              <p className='font-bold'>{l.name}</p>
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default ChangeLanguageModal
